import React, { useState, useRef, useEffect } from "react";
import { ArrowBendDoubleUpLeft, CalendarBlank, Eraser, Plus } from "phosphor-react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, getDatePickerLocale, isBlocked, showError, showSuccess, validateIBAN } from "../../hooks/utilities";
import SignatureCanvas from "react-signature-canvas";
import Toasts from "../Toasts/Toasts";
import { validateEmail } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import Spinner from "react-bootstrap/Spinner";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  showMessage,
} from "../../actions/messages";
import BenchmarkServices from "../../services/benchmark-services";
import { GET_BENCHMARK_DETAIL } from "../../actions/action-type";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getBenchmarkDetail } from "../../actions/customer";
import DatePicker from "react-datepicker";
import { createScheduleDetails } from "../../actions/benchmark-actions/benchmark-actions";
import addDays from "date-fns/addDays";
import moment from "moment";
import DocumentsServices from "../../services/documents-services";
import userpaymnetServices from "../../services/userpaymnet-services";
import Select from "react-select";
import IBanEditModal from "./IBanEditModal";


const AddWalletProposalModal = ({
  projectId,
  show,
  handleClose,
  callBack,
  estimate,
  external,
  hash,
  type = "",
  benchmark,
  setReadOnlyMode,
  showIBANField,
  isExternal,
  showIbanSelection = false,
  ibanID = 0,
  showIbanModal = false
}) => {
  const { benchmarkDetail, IBANAccounts } = useSelector((state) => state.customer);
  const { createScheduleDetail } = useSelector(
    (state) => state.benchmarkReducer
  );

  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(spaces) : [];

  let currentSpace = benchmarkDetail && benchmarkDetail.project_id ? spaces.find((w) => w.project_id == benchmarkDetail.project_id) : {};

  const fullName = localStorage.getItem("full_name");
  const email = localStorage.getItem("email");
  const showEstimateTermsAccept = type === "Proposal" && [0, 2].includes(Number(currentSpace.status)) && estimate.estimate_id > 0

  const extfirstName = estimate && estimate.contacts && estimate.contacts.firstname ?  estimate.contacts.firstname : '';
  const extlastName = estimate && estimate.contacts && estimate.contacts.lastname?  estimate.contacts.lastname : '';
  const extEmail = estimate && estimate.contacts && estimate.contacts.email ? estimate.contacts.email : '';
  const [paymentaccountTab, setPaymentaccountTab] = useState("Stripe");

  const [loder, setLoder] = useState(false);
  const [firstName, setFirstName] = useState(
    isExternal ? extfirstName :
      fullName ? fullName.split(" ")[0].toString() : ""
  );
  const [lastName, setLastName] = useState(
    isExternal ? extlastName :
      fullName ? fullName.split(" ").slice(1).toString() : ""
  );
  const [emailAddress, setEmailAddress] = useState(isExternal ? extEmail : email);
  const [serviceStartDate, setServiceStartDate] = useState(
    benchmarkDetail && benchmarkDetail.startdate
      ? new Date(benchmarkDetail.startdate)
      : ""
  );
  const [basicDetails, setBasicDetails] = useState({
    amount: 0,
    isUnKnownBudget: false,
  });
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const userType = localStorage.getItem("user_type");
  const [ibanData, setIbanData] = useState({
    ibanNumber: "",
    spaceTitle: estimate.company_name,
    billingAddress: estimate.address,
    key: "l_single",
  });
  const [useSameAddress, setUseSameAddress] = useState(true);
  const [accpetEstimateTerms, setAccpetEstimateTerms] = useState(true);
  const [IBANListing, setIBANListing] = useState([]);
  const [showAddIBANModal, setShowAddIBANModal] = useState(false);
  const [IBanDetails, setIBanDetails] = useState({
    account_iban:"",
    company_name:localStorage.getItem("company_name") ,
    city: "",
    postal_code:"",
    company_address: localStorage.getItem("company_address"),
    estimate_id: estimate.estimate_id ? estimate.estimate_id : 0
  });
const [selectedPaymentID, setselectedPaymentID] = useState(estimate.user_payment_id)
const [selectedPayment, setselectedPayment] = useState([])

  const signaturepadRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dashboardBenchmarkId = !isNaN(Number(searchParams.get("dashboard-benchmark-id"))) && Number(searchParams.get("dashboard-benchmark-id")) ? Number(searchParams.get("dashboard-benchmark-id")) : 0;

  const trimSignaturePad = () => {};

  const clearSignaturePad = () => {
    if (signaturepadRef.current && signaturepadRef.current.clear) {
      signaturepadRef.current.clear();
    }
  };

  const undoSignature = () => {
    if (signaturepadRef.current && signaturepadRef.current.toData()) {
      let signaturePadData = signaturepadRef.current.toData();
      signaturePadData.pop();
      signaturepadRef.current.fromData(signaturePadData);
    }
  };

  const validPaymentInfo = () => {
    let flag = true;

    if (!ibanData.ibanNumber || !validateIBAN(ibanData.ibanNumber)) {
      showError("l_please_enter_valid_iban_number");
      flag = false;
    } else if (!useSameAddress && !ibanData.billingAddress.trim().length) {
      showError("l_please_add_space_title");
      flag = false;
    } else if (!useSameAddress && !ibanData.spaceTitle.trim().length) {
      showError("l_please_enter_valid_billing_address");
      flag = false;
    }

    return flag;
  };

  const handleSave = async () => {
    const packageId = userType == "operator" ? 5 : 2;

    if (!firstName || !firstName.trim().length) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_first_name"))
      );
      return;
    } else if (!lastName || !lastName.trim().length) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_last_name"))
      );
      return;
    } else if (
      !emailAddress ||
      !emailAddress.trim().length ||
      !validateEmail(emailAddress)
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_email_address")
        )
      );
      return;
    } else if (
      signaturepadRef &&
      signaturepadRef.current &&
      signaturepadRef.current.isEmpty()
    ) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_add_the_signature"))
      );
      return;
    } else if (
      type == "Proposal" &&
      benchmarkDetail.start_date_is_empty == 1 &&
      !benchmarkDetail.startdate &&
      !serviceStartDate
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_select_the_start_date")
        )
      );
    }
    // else if(type == "estimate" && !serviceStartDate)
    // {
    //   showError("l_please_select_the_start_date");
    // }
    else if(moment().isAfter(serviceStartDate))
    {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_invalid_start_date")
        )
      );
    } 
    else if(showEstimateTermsAccept && !accpetEstimateTerms)
    {
      showError("l_please_accept_terms_and_conditions");
    }
    else {
      let url = signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png")
      setLoder(true);
     if (type == "Proposal") {
        // if (showIBANField) {
        //   if (validPaymentInfo()) {
        //     try {
        //       let formattedIBANData =
        //         ibanData.key === "l_single"
        //           ? [
        //               ibanData.ibanNumber,
        //               ibanData.spaceTitle,
        //               ibanData.billingAddress,
        //             ]
        //           : [
        //               {
        //                 account_iban: ibanData.ibanNumber,
        //                 billing_address: ibanData.billingAddress,
        //                 title: ibanData.spaceTitle,
        //                 project_id: projectId,
        //               },
        //             ];
        //       let key = ibanData.key.replace("l_", "");

        //       const response = await CustomerServices.setIBANSetting(
        //         key,
        //         formattedIBANData
        //       );
        //       if (!response.status) {
        //         showError(response.message);
        //         return false;
        //       }
        //     } catch (e) {
        //       setLoder(false);
        //       console.error(e);
        //     }
        //     finally
        //     {
        //       // setLoder(false);
        //     }
        //   } else {
        //     setLoder(false);
        //     return false;
        //   }
        // }

        if(currentSpace)
        {
          if([0, 2].includes(Number(currentSpace.status)) && estimate.estimate_id > 0)
          {


            userpaymnetServices.setNewIBANForWallet(
              IBanDetails.account_iban,
              IBanDetails.company_address,
              IBanDetails.city,
              IBanDetails.postal_code,
              IBanDetails.estimate_id,
              benchmarkDetail.id,
              IBanDetails.company_name,
              firstName,
              lastName,
              emailAddress,
              url,
              estimate.proposal_id,
              serviceStartDate,
            ).then((res)=>{
              if (res && res.status) {
                if (res.data.site) {
                  window.open(res.data.site, "_blank");
                  showSuccess("l_iban_added");
                }
              } else {
              }
            }).finally(()=>{
              setLoder(false);
              handleClose();
            })
            // setLoder(true);
            // CustomerServices.estimateCreate(
            //   estimate.estimate_id,
            //   firstName,
            //   lastName,
            //   emailAddress,
            //   signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png"),
            //   packageId,
            //   external,
            //   hash,
            //   moment(serviceStartDate).format("YYYY-MM-DD"),
            //   ibanData.ibanNumber,
            //   estimate.proposal_id
            // ).then(async(res) => {
            //   if (res.status) {
            //     if(res.data && res.data.site)
            //     {
            //       window.location.href = res.data.site;
            //     }
            //     if (benchmarkDetail) {
            //       let proposalBenchmark = benchmarkDetail;
            //       for (let i = 0; i < proposalBenchmark.proposal_list.length; i++) {
            //         // if (benchmark.date == proposalBenchmark.proposal_list[i].date) {
            //         for (
            //           let j = 0;
            //           j < proposalBenchmark.proposal_list[i].proposals.length;
            //           j++
            //         ) {
            //           if (
            //             estimate.proposal_id ==
            //             proposalBenchmark.proposal_list[i].proposals[j].proposal_id
            //           ) {
            //             proposalBenchmark.proposal_list[i].proposals[j].status =
            //               "3";
            //           } else {
            //             proposalBenchmark.proposal_list[i].proposals[j].status =
            //               "2";
            //           }
            //         }
            //       }
            //       dispatch({
            //         type: GET_BENCHMARK_DETAIL,
            //         payload: { data: proposalBenchmark },
            //       });
            //     }
            //     if (res.data.subscription_id && !estimate.is_custom_invoice) {
            //       if (estimate.is_iban_set > 0) {
            //         await subscribe(res.data.subscription_id, res.data.hash);
            //         callBack();
            //         dispatch(
            //           showMessage(
            //             "success",
            //             _l("l_success"),
            //             _l("l_proposal_accepted")
            //           )
            //         );
            //       }
            //     }
            //     if (res.data.schedule_id > 0) {
            //       dispatch(getBenchmarkDetail(res.data.schedule_id));
            //       if(dashboardBenchmarkId)
            //       {
            //         navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
            //       }
            //       else {
            //         navigate(
            //           `/connect-provider?space=${benchmark.project_id}&provider-type=schedule&id=${res.data.schedule_id}`
            //         );
            //       }
            //     } else {
            //       dispatch(getBenchmarkDetail(benchmarkDetail.id));
            //     }
            //     if(dashboardBenchmarkId)
            //     {
            //       navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
            //     }
            //     else {
            //       navigate("/");
            //     }
            //     handleClose();
            //     setLoder(false);
            //     setReadOnlyMode(true);
            //   } else {
            //     dispatch(showMessage("unsucess", _l("l_error"), res.message));
            //   }
            // }).finally(() => {
            //   setLoder(false);
            // });
          }
          else {
            // BenchmarkServices.acceptProposal(
            //   estimate.proposal_id,
            //   estimate.id,
            //   signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png"),
            //   firstName,
            //   lastName,
            //   emailAddress,
            //   serviceStartDate ? moment(serviceStartDate).format("YYYY-MM-DD") : "",
            //   benchmarkDetail.is_added_by_myr,
            //   ibanData.ibanNumber
            // ).then(async(res) => {
            //   if (res.status) {
            //     if (benchmarkDetail) {
            //       let proposalBenchmark = benchmarkDetail;
            //       for (let i = 0; i < proposalBenchmark.proposal_list.length; i++) {
            //         // if (benchmark.date == proposalBenchmark.proposal_list[i].date) {
            //         for (
            //           let j = 0;
            //           j < proposalBenchmark.proposal_list[i].proposals.length;
            //           j++
            //         ) {
            //           if (
            //             estimate.proposal_id ==
            //             proposalBenchmark.proposal_list[i].proposals[j].proposal_id
            //           ) {
            //             proposalBenchmark.proposal_list[i].proposals[j].status =
            //               "3";
            //           } else {
            //             proposalBenchmark.proposal_list[i].proposals[j].status =
            //               "2";
            //           }
            //         }
            //         // }
            //       }
    
            //       dispatch({
            //         type: GET_BENCHMARK_DETAIL,
            //         payload: { data: proposalBenchmark },
            //       });
            //     }
            //     if (res.data.subscription_id  && !estimate.is_custom_invoice) {
            //       if (estimate.is_iban_set > 0) {
            //         await subscribe(res.data.subscription_id, res.data.hash);
            //         callBack();
            //         dispatch(
            //           showMessage(
            //             "success",
            //             _l("l_success"),
            //             _l("l_proposal_accepted")
            //           )
            //         );
            //       }
            //     }
            //     if (res.data.schedule_id > 0) {
            //       dispatch(getBenchmarkDetail(res.data.schedule_id));
            //       if(dashboardBenchmarkId)
            //       {
            //         navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
            //       }
            //       else {
            //         navigate(
            //           `/connect-provider?space=${benchmark.project_id}&provider-type=schedule&id=${res.data.schedule_id}`
            //         );
            //       }
            //     } else {
            //       dispatch(getBenchmarkDetail(benchmarkDetail.id));
            //     }
            //     handleClose();
            //     setLoder(false);
            //     setReadOnlyMode(true);
            //     if(dashboardBenchmarkId)
            //     {
            //       navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
            //     }
            //     else {
            //       navigate("/");
            //     }
            //     // dispatch(showMessage('sucess', _l('l_success'), res.message));
            //   } else {
            //     dispatch(showMessage("unsucess", _l("l_error"), res.message));
            //   }
            // });
          }
        }

      }
    }
  };

  const saveButtonText = () => {
    if (showIBANField) {
      return _l("l_accept_and_subscribe");
    } else {
      return _l("l_save");
    }
  };

  const getSpaceIBANData = async() => {
    try {
      const response = await CustomerServices.getIbanList();
      if(response.data)
      {
        if(response.data.single_iban_for_all_spaces && response.data.account_iban)
        {
          const {billing_address, account_iban, title} = response.data;
          setIbanData({billingAddress: billing_address, ibanNumber: account_iban, spaceTitle: title, key: "l_single"});
          setUseSameAddress(false);
        }
        else {
          //Need to handle this
        }
      }
    }
    catch(e)
    {
      console.log(e);
    }
  };


  useEffect(() => {
    if (createScheduleDetail && Object.keys(createScheduleDetail).length) {
      setBasicDetails(createScheduleDetail);
    } else {
      setBasicDetails({});
    }
  }, [JSON.stringify(createScheduleDetail)]);

  useEffect(() => {
      if(type === "Proposal")
      {
        getSpaceIBANData();
      }

          if (IBANAccounts && IBANAccounts.length > 0) {
            setIBANListing(IBANAccounts.map((w) => {
              {
                return {
                  label: w.account_iban,
                  value: w.id,
                };
              }
            }))
            setselectedPaymentID(IBANAccounts && IBANAccounts.length && IBANAccounts[0].id)
      }
  }, [])
  
  return (
    <>
      {showToast ? (
        <Toasts
          delay={2500}
          handler={() => {
            setShowToast(false);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonHandler={() => {
            setShowToast(false);
          }}
          cancelButtonText={"cancel"}
          confirmButtonText={"Delete"}
        />
      ) : (
        <></>
      )}
      <div className="">
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {_l("l_signature_conformation_of_identity")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-wrapper-main">
            <div className="row">
            <div className=" col-lg-6 ">
              <div className="row">
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l("l_first_name")}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_first_name_placeholder")}`}
                    type="text"
                    name=""
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Form.Group>
              
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l("l_email")}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_email_placeholder")}`}
                    type="email"
                    name=""
                    value={emailAddress}
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                    }}
                  />
                </Form.Group>
                {["Proposal"].includes(type) ? (
                  <React.Fragment>
                    <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                      <Form.Label className="input-label no-asterisk">
                        {_l("l_service_start_date")}
                      </Form.Label>
                      <div className="custom-datepicker" data-bs-toggle="tooltip">
                        <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                          <CalendarBlank
                            size={18}
                            weight="light"
                            className="c-icons m-0 me-2"
                          />
                          <DatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            onChange={(date) => {
                              dispatch(
                                createScheduleDetails({
                                  ...basicDetails,
                                  startDate: date,
                                  isUnKnownStartDate: false,
                                })
                              );
                              setServiceStartDate(date);
                            }}
                            disabledKeyboardNavigation
                            selected={serviceStartDate}
                            placeholderText={_l("l_select_start_date")}
                            locale={getDatePickerLocale()}
                            dateFormat="dd/MM/yyyy"
                            selectsStart
                            minDate={addDays(new Date(), 1)}
                            defaultMenuIsOpen
                            calendarStartDay={1}
                          ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                        </div>
                      </div>
                    </Form.Group>
                    {
                      !estimate.is_custom_invoice && type === "proposal"
                      ?
                      <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_total_payable_amount")}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          disabled
                          value={estimate.proposal_total}
                        />
                      </Form.Group>
                      :
                      <React.Fragment></React.Fragment>
                    }
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {showIBANField && !estimate.is_custom_invoice ? (
                  <React.Fragment>
                    {/* <Form.Group className="d-flex py-1 justify-content-start">
                      <Form.Check
                        name="package-type"
                        type="radio"
                        label={_l("l_single")}
                        value={"l_single"}
                        id="disabled-default"
                        className="me-3"
                        checked={ibanData.key === "l_single"}
                        onChange={(e) => {
                          setIbanData({ ...ibanData, key: e.target.value });
                        }}
                      />
                      <Form.Check
                        name="package-type"
                        type="radio"
                        label={_l("l_individual")}
                        value={"l_individual"}
                        id="disabled-default"
                        className="me-3"
                        checked={ibanData.key === "l_individual"}
                        onChange={(e) => {
                          setIbanData({ ...ibanData, key: e.target.value });
                        }}
                      />
                      <Form.Check
                        name="package-type"
                        type="radio"
                        label={_l("l_individual_with_software")}
                        value={"l_individual_with_software"}
                        id="disabled-default"
                        className="me-3"
                        checked={ibanData.key === "l_individual_with_software"}
                        onChange={(e) => {
                          setIbanData({ ...ibanData, key: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                      <Form.Label className="input-label no-asterisk ">
                        {_l("l_account_iban")}
                      </Form.Label>
                      <Form.Control
                        placeholder={_l("l_account_iban_placeholder")}
                        type="text"
                        name=""
                        value={ibanData.ibanNumber}
                        onBlur={(e) => isValidIBAN(e.target.value)}
                        onChange={(e) => {
                          setIbanData({
                            ...ibanData,
                            ibanNumber: e.target.value,
                          });
                        }}
                      />
                    </Form.Group> */}
                    {!useSameAddress ? (
                      <React.Fragment>
                        <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                          <Form.Label className="input-label no-asterisk ">
                            {_l("l_space_title")}
                          </Form.Label>
                          <Form.Control
                            placeholder={_l("Microsoft")}
                            type="text"
                            name=""
                            value={ibanData.spaceTitle}
                            onChange={(e) => {
                              setIbanData({
                                ...ibanData,
                                spaceTitle: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                          <Form.Label className="input-label no-asterisk ">
                            {_l("l_billing_address")}
                          </Form.Label>
                          <Form.Control
                            placeholder={_l("l_billing_address_placeholder")}
                            type="text"
                            name=""
                            value={ibanData.billingAddress}
                            onChange={(e) => {
                              setIbanData({
                                ...ibanData,
                                billingAddress: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </React.Fragment>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <Form.Group className="col-xl-12 c-input-box py-2 position-relative">
                      <Form.Check
                        type={"checkbox"}
                        label={_l("l_use_postal_address_as_billing_address")}
                        checked={useSameAddress}
                        onChange={(e) => setUseSameAddress(e.target.checked)}
                      />
                    </Form.Group>
                  </React.Fragment>
                ) : (
                  <></>
                )}
                {showIbanSelection ?
                  <div className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_wallets")}
                    </Form.Label>
                    <div className="row comman_action_icon">
                      <div className="col-auto flex-grow-1 ">
                        <Select
                          placeholder={_l("l_select_space_selection")}
                          className="custom-select-menu"
                          classNamePrefix="react-select"
                          options={IBANListing}
                          defaultValue={IBANListing.filter((w) => w.value == ibanID)}
                          onChange={(e) => {
                            // setSingleSpaceBillingAddress(e.target.value);
                            setselectedPaymentID(e.value);
                            setselectedPayment(e);
                          }}
                          value={selectedPayment.length > 0 ? selectedPayment :  IBANListing.filter((w) => w.value == ibanID)}
                        />
                      </div>
                      <a href="#/" className="col-12 action_icon with_bg h53w53 me-3" onClick={()=>{
                        setShowAddIBANModal(true)
                        }}>
                        <Plus
                          size={30}
                          className="c-icons"
                          weight="light"
                        />


                      </a>
                    </div>

                  </div> : <></>}
                <Form.Group className="col-xl-12 c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l("l_signature")}
                  </Form.Label>
                </Form.Group>
                <div className="col-xl-12  pb-3">
                  <SignatureCanvas
                    penColor="white"
                    ref={signaturepadRef}
                    canvasProps={{
                      height: 200,
                      className: "custom-canvas-style form-control p-0 w-100",
                    }}
                  />
                </div>
                <div className="col-xl-12">
                  <div className="d-flex">
                    <a href="#/"
                      className="h40w40 d-flex  form-control p-0  me-3"
                      onClick={undoSignature}
                      data-tip={_l("l_undo")}
                      data-delay-show='1000'
                      data-class="tooltip-main"
                    >
                      <ArrowBendDoubleUpLeft
                        size={18}
                        className="c-icons"
                        weight="light"
                      />
                    </a>
                    <a href="#/"
                      className="h40w40 d-flex  form-control p-0"
                      onClick={clearSignaturePad}
                      data-tip={_l("l_clear")}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                    >
                      <Eraser size={18} className="c-icons" weight="light" />
                    </a>
                  </div>
                  <div className="input-label no-asterisk title-fonts c-font f-12 color-white-60 mt-2">
                    {_l("l_singature_subtext")}
                  </div>
                </div>
                {showEstimateTermsAccept ? (
                  <Form.Group className="col-xl-12 c-input-box py-2 position-relative">
                   <div className="d-flex align-items-center">
                      <label className="form-check-label  me-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`default`}
                          label="I Accept this Estimate"
                          onChange={(e) => {setAccpetEstimateTerms(e.target.checked)}}
                          checked={accpetEstimateTerms}
                        />
                      </label>
                      <a href="#/"
                        className="color-white-80"
                        onClick={(e) => {setAccpetEstimateTerms(!accpetEstimateTerms)}}
                      >
                        {_l("l_i_accept_this_estimate")}
                      </a>
                      <a
                        className="color-green ms-1"
                        href={"https://myr.ai/cgsc.html"}
                        target="_blank"
                      >
                        CGSC
                      </a>
                      <a
                        className="color-green ms-1"
                        href={"https://myr.ai/cgsp.html"}
                        target="_blank"
                      >
                        CGSP
                      </a>
                      <span className="m-1">&</span>
                      <a
                        className="color-green ms-1"
                        href={"https://myr.ai/privacy-policy.html"}
                        target="_blank"
                      >
                        RGPD
                      </a>
                    </div>
                </Form.Group>
                ) : (
                  <></>
                )}
              </div>
            </div>
              <div className=" col-lg-6 ">
              <div className="row">
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l("l_last_name")}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_last_name_placeholder")}`}
                    type="text"
                    name=""
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="col-12 c-input-box position-relative mb-3">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_company_name")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_space_title_placeholder")}
                    type="text"
                    name=""
                    value={IBanDetails.company_name}
                    onChange={(e) => {
                      const reg = new RegExp("^[A-Za-z0-9d, ]+$");
                      const inputValue = e.target.value;
                      if (reg.test(inputValue) || inputValue == ""){
                        setIBanDetails({...IBanDetails,company_name : inputValue});
                      }
                        // setSingleSpaceTitle(inputValue);
                    }}
                  />
                </Form.Group>
                <Form.Group className="col-12 c-input-box position-relative mb-3">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_iban_edit")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_account_iban_placeholder")}
                    type="text"
                    name=""
                    value={IBanDetails.account_iban}
                    onChange={(e) => {
                      const reg = new RegExp("^[A-Za-z0-9 ]+$");
                      const inputValue = e.target.value;
                      if (reg.test(inputValue) || inputValue == ""){
                        setIBanDetails({...IBanDetails, account_iban : e.target.value});
                      }
                        // setSingleIBAN(e.target.value);
                    }}
                  />
                </Form.Group>
             
                <Form.Group className="col-12 c-input-box position-relative mb-3">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_city")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_city_placeholder")}
                    type="text"
                    name=""
                    value={IBanDetails.city}
                    onChange={(e)=>{
                      setIBanDetails({...IBanDetails,city : e.target.value});

                    }}
                  />
                </Form.Group>
                <Form.Group className="col-12 c-input-box position-relative mb-3">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_postal_code")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_postal_address_placeholder")}
                    type="number"
                    name=""
                    value={IBanDetails.postal_code}
                    onChange={(e)=>{
                      setIBanDetails({...IBanDetails,postal_code : e.target.value});
                    }}
                  />
                </Form.Group>
                <Form.Group className="col-12 c-input-box position-relative mb-3">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_billing_address")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_billing_address_placeholder")}
                    type="text"
                    as="textarea"
                    rows={4}
                    name=""
                    value={IBanDetails.company_address}
                    onChange={(e) => {
                      setIBanDetails({...IBanDetails,company_address : e.target.value})
                    }}
                  />

                </Form.Group>
                <Form.Group className="col-12 c-input-box position-relative mb-3 for-disabled">
                  <div className="row">
                    <div className="col-lg-6">
                      <Form.Check
                        type="checkbox"
                        label={"Stripe"}
                        id="disabled-default"
                        className="me-3"
                        checked={paymentaccountTab == "Stripe"}
                        onClick={() => {
                          setPaymentaccountTab("Stripe");
                        }}
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Form.Check
                        type="checkbox"
                        label={"Gocardless"}
                        id="disabled-default"
                        className="me-3"
                        checked={paymentaccountTab == "Stripe"}
                        onClick={() => {
                          setPaymentaccountTab("Stripe");
                        }}
                        disabled={true}

                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
          </div>
          
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={loder ? "" : handleSave}
              disabled={loder}
            >
              {loder ? _l("l_please_wait") : saveButtonText()}
              {loder ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <ReactTooltip />
        {showAddIBANModal ? <>
        <IBanEditModal
          show={showAddIBANModal}
          handleClose={() => {
            setShowAddIBANModal(false);
            setIBanDetails({
              account_iban:"",
              company_name:localStorage.getItem("company_name") ,
              city: "",
              postal_code:"",
              company_address: localStorage.getItem("company_address"),
              estimate_id: estimate.estimate_id ? estimate.estimate_id : 0 
            })
          }}
          isForAdd={true}
          IBanDetails={IBanDetails}
          setIBanDetails={setIBanDetails}
          handleSave={()=>{}}
          redirectIsActive={false}
        />
        </> :<></>}
      </div>
    </>
  );
};

export default AddWalletProposalModal