import React, { useState, useRef, useEffect } from "react";
import {
  ArrowBendDoubleUpLeft,
  CalendarBlank,
  Eraser,
  Plus,
} from "phosphor-react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  _l,
  getDatePickerLocale,
  isBlocked,
  showError,
  showSuccess,
  validateIBAN,
} from "../../hooks/utilities";
import SignatureCanvas from "react-signature-canvas";
import Toasts from "../Toasts/Toasts";
import { validateEmail } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import Spinner from "react-bootstrap/Spinner";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import BenchmarkServices from "../../services/benchmark-services";
import { GET_BENCHMARK_DETAIL } from "../../actions/action-type";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getBenchmarkDetail } from "../../actions/customer";
import DatePicker from "react-datepicker";
import { createScheduleDetails } from "../../actions/benchmark-actions/benchmark-actions";
import addDays from "date-fns/addDays";
import moment from "moment";
import DocumentsServices from "../../services/documents-services";
import userpaymnetServices from "../../services/userpaymnet-services";
import Select from "react-select";
import IBanEditModal from "./IBanEditModal";

const IbanAddWithSIgnatureModal = ({
  projectId,
  show,
  handleClose,
  callBack,
  estimate,
  external,
  hash,
  type = "",
  benchmark,
  setReadOnlyMode,
  showIBANField,
  isExternal,
  showIbanSelection = false,
  ibanID = 0,
}) => {
  const { benchmarkDetail } = useSelector((state) => state.customer);
  const { createScheduleDetail } = useSelector(
    (state) => state.benchmarkReducer
  );

  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(spaces) : [];

  let currentSpace =
    benchmarkDetail && benchmarkDetail.project_id
      ? spaces.find((w) => w.project_id == benchmarkDetail.project_id)
      : {};

  const fullName = localStorage.getItem("full_name");
  const email = localStorage.getItem("email");
  const showEstimateTermsAccept =
    type === "Proposal" &&
    [0, 2].includes(Number(currentSpace.status)) &&
    estimate.estimate_id > 0;

  const extfirstName =
    estimate && estimate.contacts && estimate.contacts.firstname
      ? estimate.contacts.firstname
      : "";
  const extlastName =
    estimate && estimate.contacts && estimate.contacts.lastname
      ? estimate.contacts.lastname
      : "";
  const extEmail =
    estimate && estimate.contacts && estimate.contacts.email
      ? estimate.contacts.email
      : "";
  const [paymentaccountTab, setPaymentaccountTab] = useState("Stripe");

  const [loder, setLoder] = useState(false);
  const [firstName, setFirstName] = useState(
    isExternal
      ? extfirstName
      : fullName
      ? fullName.split(" ")[0].toString()
      : ""
  );
  const [lastName, setLastName] = useState(
    isExternal
      ? extlastName
      : fullName
      ? fullName.split(" ").slice(1).toString()
      : ""
  );
  const [emailAddress, setEmailAddress] = useState(
    isExternal ? extEmail : email
  );
  const [serviceStartDate, setServiceStartDate] = useState(
    benchmarkDetail && benchmarkDetail.startdate
      ? new Date(benchmarkDetail.startdate)
      : ""
  );
  const [basicDetails, setBasicDetails] = useState({
    amount: 0,
    isUnKnownBudget: false,
  });
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const userType = localStorage.getItem("user_type");
  const [ibanData, setIbanData] = useState({
    ibanNumber: "",
    spaceTitle: estimate.company_name,
    billingAddress: estimate.address,
    key: "l_single",
  });
  const [useSameAddress, setUseSameAddress] = useState(true);
  const [accpetEstimateTerms, setAccpetEstimateTerms] = useState(true);
  const [IBANListing, setIBANListing] = useState([]);
  const [showAddIBANModal, setShowAddIBANModal] = useState(false);
  const [IBanDetails, setIBanDetails] = useState({
    account_iban: "",
    company_name:localStorage.getItem("company_name") ,
    city: "",
    postal_code: "",
    company_address: localStorage.getItem("company_address"),
    estimate_id: estimate.estimate_id ? estimate.estimate_id : 0,
  });
  const [selectedPaymentID, setselectedPaymentID] = useState(
    estimate.user_payment_id
  );
  const [selectedPayment, setselectedPayment] = useState([]);

  const signaturepadRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dashboardBenchmarkId =
    !isNaN(Number(searchParams.get("dashboard-benchmark-id"))) &&
    Number(searchParams.get("dashboard-benchmark-id"))
      ? Number(searchParams.get("dashboard-benchmark-id"))
      : 0;

  const trimSignaturePad = () => {};

  const clearSignaturePad = () => {
    if (signaturepadRef.current && signaturepadRef.current.clear) {
      signaturepadRef.current.clear();
    }
  };

  const undoSignature = () => {
    if (signaturepadRef.current && signaturepadRef.current.toData()) {
      let signaturePadData = signaturepadRef.current.toData();
      signaturePadData.pop();
      signaturepadRef.current.fromData(signaturePadData);
    }
  };

  const handleSave = async () => {
    const packageId = userType == "operator" ? 5 : 2;

    if (!firstName || !firstName.trim().length) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_first_name"))
      );
      return;
    } else if (!lastName || !lastName.trim().length) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_last_name"))
      );
      return;
    } else if (
      !emailAddress ||
      !emailAddress.trim().length ||
      !validateEmail(emailAddress)
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_email_address")
        )
      );
      return;
    }else if(!IBanDetails.account_iban || IBanDetails.account_iban == ""){
        dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_valid_iban_number")
            )
          );
        return
    }else if(!IBanDetails.company_name || IBanDetails.company_name == ""){
        dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_company_name")
            )
          );
        return
    }else if(!IBanDetails.company_address || IBanDetails.company_address == ""){
        dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_valid_address")
            )
          );
        return
    } else if(!IBanDetails.city || IBanDetails.city == ""){
        dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_valid_city")
            )
          );
        return
    }else if(!IBanDetails.postal_code || IBanDetails.postal_code == ""){
        dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_valid_postal_code")
            )
          );
        return
    }else if (
      signaturepadRef &&
      signaturepadRef.current &&
      signaturepadRef.current.isEmpty()
    ) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_add_the_signature"))
      );
      return;
    } else {
      let url = signaturepadRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setLoder(true);
      userpaymnetServices.setNewIBANForWallet(
        IBanDetails.account_iban,
        IBanDetails.company_address,
        IBanDetails.city,
        IBanDetails.postal_code,
        IBanDetails.estimate_id,
        0,
        IBanDetails.company_name,
        firstName,
        lastName,
        emailAddress,
        url
      ).then((res)=>{
        if (res && res.status) {
            window.open(res.data.site, "_blank");
            showSuccess("l_iban_added");
            setLoder(false);
            handleClose();
          }else{
        setLoder(false);
        handleClose();

          }

      })
    }
  };

  const saveButtonText = () => {
    if (showIBANField) {
      return _l("l_accept_and_subscribe");
    } else {
      return _l("l_save");
    }
  };

  const isValidIBAN = (ibanString) => {
    if (!validateIBAN(ibanString)) {
      showError("l_please_enter_valid_iban_number");
    }
  };

  useEffect(() => {
    if (createScheduleDetail && Object.keys(createScheduleDetail).length) {
      setBasicDetails(createScheduleDetail);
    } else {
      setBasicDetails({});
    }
  }, [JSON.stringify(createScheduleDetail)]);

  useEffect(() => {
    if (showIbanSelection) {
      userpaymnetServices.getIBANlisting().then((response) => {
        if (response && response.status) {
          setIBANListing(
            response.data.map((w) => {
              {
                return {
                  label: w.account_iban,
                  value: w.id,
                };
              }
            })
          );
          setselectedPaymentID(
            response.data && response.data.length && response.data[0].id
          );
        } else {
          showError("l_data_not_found");
        }
      });
    }
  }, []);

  return (
    <>
      {showToast ? (
        <Toasts
          delay={2500}
          handler={() => {
            setShowToast(false);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonHandler={() => {
            setShowToast(false);
          }}
          cancelButtonText={"cancel"}
          confirmButtonText={"Delete"}
        />
      ) : (
        <></>
      )}
      <div className="">
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {_l("l_signature_conformation_of_identity")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-wrapper-main">
            <div className="row">
              <div className=" col-lg-6 ">
                <div className="row">
                  <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_first_name")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_first_name_placeholder")}`}
                      type="text"
                      name=""
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_email")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_email_placeholder")}`}
                      type="email"
                      name=""
                      value={emailAddress}
                      onChange={(e) => {
                        setEmailAddress(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-12 c-input-box position-relative mb-3">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_iban_edit")}
                    </Form.Label>
                    <Form.Control
                      placeholder={_l("l_account_iban_placeholder")}
                      type="text"
                      name=""
                      value={IBanDetails.account_iban}
                      onChange={(e) => {
                        const reg = new RegExp("^[A-Za-z0-9 ]+$");
                        const inputValue = e.target.value;
                        if (reg.test(inputValue) || inputValue == "") {
                          setIBanDetails({
                            ...IBanDetails,
                            account_iban: e.target.value,
                          });
                        }
                        // setSingleIBAN(e.target.value);
                      }}
                    />
                  </Form.Group>
                  {showIBANField && !estimate.is_custom_invoice ? (
                    <React.Fragment>
                      <Form.Group className="d-flex py-1 justify-content-start">
                        <Form.Check
                          name="package-type"
                          type="radio"
                          label={_l("l_single")}
                          value={"l_single"}
                          id="disabled-default"
                          className="me-3"
                          checked={ibanData.key === "l_single"}
                          onChange={(e) => {
                            setIbanData({ ...ibanData, key: e.target.value });
                          }}
                        />
                        <Form.Check
                          name="package-type"
                          type="radio"
                          label={_l("l_individual")}
                          value={"l_individual"}
                          id="disabled-default"
                          className="me-3"
                          checked={ibanData.key === "l_individual"}
                          onChange={(e) => {
                            setIbanData({ ...ibanData, key: e.target.value });
                          }}
                        />
                        <Form.Check
                          name="package-type"
                          type="radio"
                          label={_l("l_individual_with_software")}
                          value={"l_individual_with_software"}
                          id="disabled-default"
                          className="me-3"
                          checked={
                            ibanData.key === "l_individual_with_software"
                          }
                          onChange={(e) => {
                            setIbanData({ ...ibanData, key: e.target.value });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_account_iban")}
                        </Form.Label>
                        <Form.Control
                          placeholder={_l("l_account_iban_placeholder")}
                          type="text"
                          name=""
                          value={ibanData.ibanNumber}
                          onBlur={(e) => isValidIBAN(e.target.value)}
                          onChange={(e) => {
                            setIbanData({
                              ...ibanData,
                              ibanNumber: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      {!useSameAddress ? (
                        <React.Fragment>
                          <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                            <Form.Label className="input-label no-asterisk ">
                              {_l("l_space_title")}
                            </Form.Label>
                            <Form.Control
                              placeholder={_l("Microsoft")}
                              type="text"
                              name=""
                              value={ibanData.spaceTitle}
                              onChange={(e) => {
                                setIbanData({
                                  ...ibanData,
                                  spaceTitle: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                            <Form.Label className="input-label no-asterisk ">
                              {_l("l_billing_address")}
                            </Form.Label>
                            <Form.Control
                              placeholder={_l("l_billing_address_placeholder")}
                              type="text"
                              name=""
                              value={ibanData.billingAddress}
                              onChange={(e) => {
                                setIbanData({
                                  ...ibanData,
                                  billingAddress: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </React.Fragment>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <Form.Group className="col-xl-12 c-input-box py-2 position-relative">
                        <Form.Check
                          type={"checkbox"}
                          label={_l("l_use_postal_address_as_billing_address")}
                          checked={useSameAddress}
                          onChange={(e) => setUseSameAddress(e.target.checked)}
                        />
                      </Form.Group>
                    </React.Fragment>
                  ) : (
                    <></>
                  )}
                  <Form.Group className="col-xl-12 c-input-box  position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_signature")}
                    </Form.Label>
                  </Form.Group>
                  <div className="col-xl-12  pb-3">
                    <SignatureCanvas
                      penColor="white"
                      ref={signaturepadRef}
                      canvasProps={{
                        height: 200,
                        className: "custom-canvas-style form-control p-0 w-100",
                      }}
                    />
                  </div>
                  <div className="col-xl-12">
                    <div className="d-flex">
                      <a
                        href="#/"
                        className="h40w40 d-flex  form-control p-0  me-3"
                        onClick={undoSignature}
                        data-tip={_l("l_undo")}
                        data-delay-show="1000"
                        data-class="tooltip-main"
                      >
                        <ArrowBendDoubleUpLeft
                          size={18}
                          className="c-icons"
                          weight="light"
                        />
                      </a>
                      <a
                        href="#/"
                        className="h40w40 d-flex  form-control p-0"
                        onClick={clearSignaturePad}
                        data-tip={_l("l_clear")}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                      >
                        <Eraser size={18} className="c-icons" weight="light" />
                      </a>
                    </div>
                    <div className="input-label no-asterisk title-fonts c-font f-12 color-white-60 mt-2">
                      {_l("l_singature_subtext")}
                    </div>
                  </div>
       
                </div>
              </div>
              <div className=" col-lg-6 ">
                <div className="row">
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_last_name")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_last_name_placeholder")}`}
                      type="text"
                      name=""
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Form.Group>
     
                  <Form.Group className="col-12 c-input-box position-relative mb-3">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_company_name")}
                    </Form.Label>
                    <Form.Control
                      placeholder={_l("l_company_name")}
                      type="text"
                      name=""
                      value={IBanDetails.company_name}
                      onChange={(e) => {
                        const reg = new RegExp("^[A-Za-z0-9d, ]+$");
                        const inputValue = e.target.value;
                        if (reg.test(inputValue) || inputValue == "") {
                          setIBanDetails({
                            ...IBanDetails,
                            company_name: inputValue,
                          });
                        }
                        // setSingleSpaceTitle(inputValue);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-12 c-input-box position-relative mb-3">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_city")}
                    </Form.Label>
                    <Form.Control
                      placeholder={_l("l_city_placeholder")}
                      type="text"
                      name=""
                      value={IBanDetails.city}
                      onChange={(e) => {
                        setIBanDetails({
                          ...IBanDetails,
                          city: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-12 c-input-box position-relative mb-3">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_postal_code")}
                    </Form.Label>
                    <Form.Control
                      placeholder={_l("l_postal_address_placeholder")}
                      type="number"
                      name=""
                      value={IBanDetails.postal_code}
                      onChange={(e) => {
                        setIBanDetails({
                          ...IBanDetails,
                          postal_code: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-12 c-input-box position-relative mb-3">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_billing_address")}
                    </Form.Label>
                    <Form.Control
                      placeholder={_l("l_billing_address_placeholder")}
                      type="text"
                      as="textarea"
                      rows={4}
                      name=""
                      value={IBanDetails.company_address}
                      onChange={(e) => {
                        setIBanDetails({
                          ...IBanDetails,
                          company_address: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-12 c-input-box position-relative mb-3 for-disabled">
                    <div className="row">
                      <div className="col-lg-6">
                        <Form.Check
                          type="checkbox"
                          label={"Stripe"}
                          id="disabled-default"
                          className="me-3"
                          checked={paymentaccountTab == "Stripe"}
                          onClick={() => {
                            setPaymentaccountTab("Stripe");
                          }}
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-6">
                        <Form.Check
                          type="checkbox"
                          label={"Gocardless"}
                          id="disabled-default"
                          className="me-3"
                          checked={paymentaccountTab == "Stripe"}
                          onClick={() => {
                            setPaymentaccountTab("Stripe");
                          }}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={loder ? "" : handleSave}
              disabled={loder}
            >
              {loder ? _l("l_please_wait") : saveButtonText()}
              {loder ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <ReactTooltip />
        {showAddIBANModal ? (
          <>
            <IBanEditModal
              show={showAddIBANModal}
              handleClose={() => {
                setShowAddIBANModal(false);
                setIBanDetails({
                  account_iban:"",
                  company_name:localStorage.getItem("company_name") ,
                  city: "",
                  postal_code:"",
                  company_address: localStorage.getItem("company_address"),
                  estimate_id: estimate.estimate_id ? estimate.estimate_id : 0,
                });
              }}
              isForAdd={true}
              IBanDetails={IBanDetails}
              setIBanDetails={setIBanDetails}
              handleSave={() => {}}
              redirectIsActive={false}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default IbanAddWithSIgnatureModal;
